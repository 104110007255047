import { BaseEntityModel } from '@common-src/model/base-model';
import { FormControl, FormControlType, FormControlTextModel, FormControlOptionModel } from '@common-src/model/form-control';
import { QueryModel } from '@common-src/model/query-model';
import { QueryControl, QueryControlType } from '@common-src/model/query-control';
import { PermissionType, ResourceType } from '@common-src/model/enum';
export class PermissionEntityModel extends BaseEntityModel {
    @FormControl({
        label: '资源名称',
        type: FormControlType.TEXT,
        required: true
    } as FormControlTextModel)
    name: string = undefined;
    resourceName: string = undefined;

    @FormControl({
        label: '标识符',
        type: FormControlType.TEXT,
        required: true,
        max: 200
    } as FormControlTextModel)
    identifier: string = undefined;
    resourceCode: string = undefined;

    @FormControl({
        label: '类型',
        type: FormControlType.RADIO_GROUP,
        required: true,
        options: [
            { name: '页面', value: ResourceType.PAGE },
            { name: '功能', value: ResourceType.FUNCTION }
        ]
    } as FormControlOptionModel)
    category: ResourceType = undefined;
    categoryDesc: string = undefined;

    @FormControl({
        label: '序号',
        type: FormControlType.NUMBER
    } as FormControlTextModel)
    sortOrder: string = undefined;

    // @FormControl({
    //     label: '资源',
    //     type: FormControlType.RESOURCE_URL,
    //     options: [
    //         { name: 'GET', value: 'GET' },
    //         { name: 'HEAD', value: 'HEAD' },
    //         { name: 'POST', value: 'POST' },
    //         { name: 'PUT', value: 'PUT' },
    //         { name: 'PATCH', value: 'PATCH' },
    //         { name: 'DELETE', value: 'DELETE' },
    //         { name: 'OPTIONS', value: 'OPTIONS' },
    //         { name: 'TRACE', value: 'TRACE' }
    //     ],
    //     invisible: true
    // } as FormControlOptionModel)
    // resources: Array<{httpMethod: string, url: string}> = [{ httpMethod: 'GET', url: '' }];

    @FormControl({
        label: '描述',
        type: FormControlType.TEXT_AREA
    } as FormControlTextModel)
    remark: string = undefined;

    resources: Array<string> = undefined;

    children: Array<PermissionEntityModel> = undefined;
    select: boolean = false;
    type: PermissionType = undefined;
    level: number = undefined;
    parent: PermissionEntityModel;
    groupModel: any;
    groupId: string = undefined;
    mediaType: string = undefined;
    modelStatus: string = undefined;
    parentId: string = undefined;

    get AllSelect(): boolean {
        if (this.children && this.children.length > 0) {
            this.select = _.filter(this.children, item => item.select).length === this.children.length;
        }
        return this.select;
    }
    set AllSelect(val: boolean) {
        _.forEach(this.children, item => {
            item.select = val;
            item.AllSelect = val;
        });
        this.select = val;
    }
    get Indeterminate() {
        if (this.children && this.children.length > 0) {
            const selectLength = _.filter(this.children, item => item.select).length;
            const hasIndeterminate = _.filter(this.children, item => item.Indeterminate).length > 0;
            return hasIndeterminate || (selectLength > 0 && selectLength < this.children.length);
        }
        return false;
    }

    get HasChildren() {
        return this.children && this.children.length > 0;
    }

    setSelect(selectedPermissions: Array<string>, keyField = 'id') {
        const selectedPermission = _.find(selectedPermissions, item => item === this[keyField]);
        if (this.level === 1 || selectedPermission) {
            this.select = true;
            _.forEach(this.children, item => {
                item.setSelect(selectedPermissions, keyField);
            });
        } else {
            this.select = false;
        }
    }

    setTopoSelect(selectedPermissions: Array<string>, keyField = 'id') {
        const selectedPermission = _.find(selectedPermissions, item => item === this[keyField]);
        if (this.level === 1 || this.level === 2 || selectedPermission) {
            this.select = true;
            _.forEach(this.children, item => {
                item.setTopoSelect(selectedPermissions, keyField);
            });
        } else {
            this.select = false;
        }
    }

    static getTableColumns() {
        return [
            {
                title: '名称',
                dataIndex: 'name',
                scopedSlots: { customRender: 'name' }
            },
            {
                title: '序号',
                dataIndex: 'sortOrder'
            },
            {
                title: '类型',
                dataIndex: 'categoryDesc'
            },
            {
                title: '标识符',
                dataIndex: 'identifier'
            },
            {
                title: '描述',
                dataIndex: 'remark'
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' }
            }
        ];
    }

    static getServiceTableColumns() {
        return [
            {
                title: '名称',
                dataIndex: 'name',
                scopedSlots: { customRender: 'name' },
                width: 250
            },
            {
                title: '服务',
                dataIndex: 'resources',
                scopedSlots: { customRender: 'serviceResources' }
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'serviceAction' },
                width: 100
            }
        ];
    }

    toModel(json): any {
        super.toModel(json);
        // this.category = ResourceType.PAGE;
        // if (!this.resources || this.resources.length === 0) {
        //     this.resources = [{ httpMethod: 'GET', url: '' }];
        // }
        if (_.get(json, 'children') || _.get(json, 'permissions')) {
            this.children = _.map(_.get(json, 'children') || _.get(json, 'permissions'), item => {
                const child: PermissionEntityModel = new PermissionEntityModel().toModel(Object.assign({ level: this.level + 1 }, item));
                child.parent = this;
                return child;
            });
        }
        return this;
    }

    toService() {
        const data: any = {
            id: this.id,
            name: this.name,
            identifier: this.identifier,
            type: this.type || _.get(this.groupModel, 'type'),
            parentId: this.parentId || _.get(this.parent, 'id'),
            groupId: this.groupId || _.get(this.groupModel, 'id'),
            mediaType: this.mediaType || _.get(this.groupModel, 'mediaType'),
            remark: this.remark,
            sortOrder: this.sortOrder,
            category: this.category
            // resources: this.resources
        };
        // if (this.HasChildren) {
        //     data.children = _.map(_.filter(this.children, item => item.select || item.Indeterminate), (item: any) => item.toService());
        // }
        return data;
    }

    toRoleService(entrance?:string) {
        const data: any = {
            id: this.id,
            identifier: this.identifier,
            name: this.name,
            resourceCode: this.resourceCode,
            resourceName: this.resourceName,
            type: this.type
        };
        if (this.HasChildren) {
            data.children = _.map(_.filter(this.children, item => item.select || item.Indeterminate || (entrance && item.category === ResourceType.FUNCTION)), (item: any) => item.toRoleService(entrance));
        }
        return data;
    }
}

export class PermissionQueryModel extends QueryModel {
    @QueryControl({
        label: '名称',
        type: QueryControlType.TEXT
    })
    name: string = undefined;

    group: string = undefined;
    type: ResourceType = undefined;

    toService() {
        return {
            params: this.group
        };
    }
}
